<template>
    <div>
	    <div class="stream-wrapper" ref="stream-wrapper">
		    <qrcode-stream @decode="onDecode"></qrcode-stream>
	    </div>
        <div class="field-input" :class="getClasses">
            <div class="form__group field">
                <input class="form__field" :id="id" :name="name" :placeholder="label" ref="input" :value="value" @input="updateValue()">
                <label :for="id" class="form__label">{{label}}</label>
                <div v-if="hasError" class="error">{{error}}</div>
            </div>
        </div>
    </div>
</template>

<script>

    import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
    import FieldInput from '@/components/inputs/FieldInput.vue';

    export default {
        name: "QRScanner",
        components: {
            QrcodeStream,
            QrcodeDropZone,
            QrcodeCapture,
            FieldInput
        },
        props: {
            label: String,
            name: String,
            value: String|Number,
            error: String,
        },
         computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
            },
            getClasses(){
				return [
					this.hasError ? this.classes+' has-error' : this.classes
				]
			},
        },
        data () {
			return {
				id: null
			}
		},
	    mounted()
	    {
	    	if(typeof this.$refs['stream-wrapper'] !== 'undefined')
		    {
		        this.squareImage();
		    }
	    	else
		    {
		        const interval = setInterval(() => {
			        if(typeof this.$refs['stream-wrapper'] !== 'undefined')
			        {
			        	clearInterval(interval);
			        	this.squareImage();
			        }
		        },20)
		    }
	    },
	    methods: {
        	squareImage(){
        		if(window.innerWidth < 767)
		        {
		        	const bounds = this.$refs['stream-wrapper'].getBoundingClientRect();
			        this.$refs['stream-wrapper'].style.cssText = 'width: '+bounds.width+'px; height: '+bounds.width+'px;';
		        }
	        },
            onDecode (decodedString) {
                this.$emit('input', decodedString);
	            this.$emit('decodedQr');
            },
            updateValue ()
            {
                this.$emit('input', this.$refs.input.value)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .field-input{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			input{
				border-bottom: 1px solid $danger;
			}
		}
		input{
			display: block;
			::placeholder{
				color: $lightGray;
			}
		}
		.error{
			color: $danger;
            padding-left: 0px;
            font-size: 0.625rem;
		}
    }
    
    .form__group {
        position: relative;
        padding: 15px 0 0;
        margin-top: 0px;
    }

    .form__field {
        font-family: inherit;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $lighterGray;
        outline: 0;
        font-size: 0.875rem;
        padding: 7px 0;
        background: transparent;
        transition: border-color 0.2s;

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown ~ .form__label {
            font-size: 0.875rem;
            cursor: text;
            top: 20px;
        }
    }

    .form__label {
        position: absolute;
        top: 5px;
        display: block;
        transition: 0.2s;
        font-size: 0.625rem;
        color: $lightGray;
        pointer-events: none;
    }

    .form__field:focus {
        ~ .form__label {
            position: absolute;
            top: 5px;
            display: block;
            transition: 0.2s;
            font-size: 0.625rem;
        }
        padding-bottom: 7px;
    }
    /* reset input */
    .form__field{
        &:required,&:invalid { box-shadow:none; }
    }
    .form__field:disabled {
	    color: $lightGray;
	    -webkit-text-fill-color: #93a3ab;
	    -webkit-opacity: 1;
    }
</style>
