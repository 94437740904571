<template>
    <div>
        <div v-if='viewState == "check-in"'>
            <div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
                <div class="h3 mb-0">{{$t("pilotCheckInOut.assignmentDetails")}}</div>
            </div>
            <spinner v-if="isFetchingRequiredApiData !== false" />
            <form-container v-else>
                <div class="col-6">
                    <div class="form__group field">
                        <p class="date-time-title">{{$t("terms.start")}}</p>
                        <p class="date">{{routeEntity.date | moment("DD-MM-YYYY")}}</p>
                        <p class="time">{{routeEntity.startHour}}:00</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form__group field">
                        <p class="date-time-title">{{$t("terms.end")}}</p>
                        <p class="date">{{routeEntity.date | moment("DD-MM-YYYY")}}</p>
                        <p class="time">{{routeEntity.endHour}}:00</p>
                    </div>
                </div>
	            <div class="col-6">
		            <div class="form__group field">
			            <p class="date-time-title">{{$t("hauler.constructionSiteOpeningHours")}}</p>
			            <p class="date">{{formatSiteOpenString(routeEntity.booking)}}</p>
		            </div>
	            </div>
	            <div class="col-6">
		            <div class="form__group field">
			            <p class="date-time-title">{{$t('hauler.additionalConstructionSiteInfo')}}</p>
			            <p class="date">{{routeEntity.booking.siteAdditionalInformation}}</p>
		            </div>
	            </div>
	            <div class="col-6">
		            <div class="form__group field">
			            <p class="date-time-title">{{$t('listHeaders.booker')}}</p>
			            <p class="date">{{$t('labels.name')}}: {{routeEntity.booking.issuer.name}}</p>
			            <p class="date">{{$t('labels.phone')}}: {{routeEntity.booking.issuer.mobile}}</p>
			            <br>
			            
		            </div>
	            </div>
                <div class="col-12 mt-3"><h3>{{$t("terms.location")}}</h3></div>
                <div class="col-12">
                     <google-map
                        class="pilot-map"
                        containerStyles="width: 100%; height: 100%; position: absolute;"
                        :map-config='{
                            zoom: 10,
                            center: {lat: bookingLat, lng: bookingLng},
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            options: {
                                gestureHandling: "none" //
                            },
                            styles: [
                                {
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                    ]
                                },
                                {
                                    "elementType": "labels.icon",
                                    "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "administrative.land_parcel",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#bdbdbd"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#dadada"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "road.local",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "transit.line",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "transit.station",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "geometry",
                                    "stylers": [
                                    {
                                        "color": "#c9c9c9"
                                    }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                    ]
                                }
                                ]
                        }'
                        :libraries="['drawing']">
                        <template  slot-scope="{ google, map }">
                            <map-marker :google="google" :map="map" :position="{lat: bookingLat, lng: bookingLng}" />
                        </template>
                    </google-map>
                    <div class="address p-2"><span>{{routeEntity.booking.address}}</span></div>
                    <div class="buttons py-4">
                        <button type="button" class="btn btn-primary btn-lg btn-block" @click="checkInOut">{{routeEntity.hasCheckedIn ? $t("pilotCheckInOut.checkOut") : $t("pilotCheckInOut.checkIn")}}</button>
	                    <template v-if="routeEntity.hasCheckedIn">
		                    <div class="pt-2" v-if="machineWasStartLoader"><spinner /></div>
		                    <button v-else type="button" class="btn btn-secondary btn-lg btn-block" @click="triggerMachineWashStart">{{$t("pilotCheckInOut.triggerMachineWashStart")}}</button>
	                    </template>
                     
                    </div>
                </div>
            </form-container>
        </div>
        <div v-else-if='viewState == "check-in-scan"'>
            <div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3" ref="scanner-title">
                <div class="h3 mb-0">{{$t("pilotCheckInOut.checkIn")}}</div>
            </div>
            <spinner v-if="isFetchingRequiredApiData !== false" />
            <form-container v-else>
                <div class="col-12">
                    <q-r-scanner :label="$t('labels.serial')" v-model='qrCode' :error='qrCodeError' @decodedQr="attemptSubmit" />
                    <div class="buttons py-4" ref="qr-buttons">
                        <div class="pt-2" v-if="checkInOutLoader"><spinner /></div>
                        <button v-else type="button" class="btn btn-lg btn-block" :class='qrCode == "" ? "btn-secondary" : "btn-primary"' @click="routeEntity.hasCheckedIn ? checkOut() : checkIn()" :disabled='qrCode == "" ? true : false'>{{routeEntity.hasCheckedIn ? $t("pilotCheckInOut.checkOut") : $t("pilotCheckInOut.checkIn")}}</button>
                    </div>
                </div>
            </form-container>
        </div>
        <div v-else-if='viewState == "check-in-success"'>
            <div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
                <div class="h3 mb-0">{{$t("pilotCheckInOut.checkInSuccess")}}</div>
            </div>
            <spinner v-if="isFetchingRequiredApiData !== false" />
            <form-container v-else>
                <div class="col-6">
                    <div class="form__group field">
                        <p class="date-time-title">{{$t("terms.start")}}</p>
                        <p class="date">{{checkInDateTime | moment("DD-MM-YYYY")}}</p>
                        <p class="time">{{checkInDateTime | moment("HH:mm")}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <p class="success-message">{{$t('pilotCheckInOut.workdayCheckInSuccess')}}</p>
                </div>
                <div class="col-12">
                    <div class="buttons py-4">
                        <button type="button" class="btn btn-primary btn-lg btn-block" @click='goToDashboard'>{{$t('terms.ok')}}</button>
                    </div>
                </div>
            </form-container>
        </div>
        <div v-else-if='viewState == "check-out-success"'>
            <div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
                <div class="h3 mb-0">{{$t("pilotCheckInOut.checkOutSuccess")}}</div>
            </div>
            <spinner v-if="isFetchingRequiredApiData !== false" />
            <form-container v-else>
                <div class="col-6">
                    <div class="form__group field">
                        <p class="date-time-title">{{$t("terms.end")}}</p>
                        <p class="date">{{checkOutDateTime | moment("DD-MM-YYYY")}}</p>
                        <p class="time">{{checkOutDateTime | moment("HH:mm")}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <p class="success-message">{{$t('pilotCheckInOut.workdayCheckOutSuccess')}}</p>
                </div>
                <div class="col-12">
                    <field-textarea :label="$t('pilotCheckInOut.addNoteLabel')" v-model='note' classes="col-12 p-0" />
					<field-input :label="$t('labels.cubicMetersUsed')" type="number" v-model="cubicMetersUsed" :classes="''" />
                </div>
                <div class="col-12">
                    <div class="buttons py-4">
                        <button type="button" class="btn btn-primary btn-lg btn-block" @click='addNote'>{{$t('pilotCheckInOut.completeAssignment')}}</button>
                    </div>
                </div>
            </form-container>
        </div>
    </div>
</template>

<script>
    import FieldInput from '@/components/inputs/FieldInput.vue';
    import FormContainer from '@/components/container/FormContainer.vue';
    import GoogleMap from '@/components/maps/GoogleMap.vue';
    import MapMarker from '@/components/maps/MapMarker.vue';
    import StandardButton from '@/components/buttons/StandardButton.vue';
    import QRScanner from '@/components/modules/QRScanner.vue';
    import FieldTextarea from '@/components/inputs/FieldTextarea.vue';
    import requiresApiRequest from "@/mixin/requiresApiRequest";
    import spinner from "@/components/loaders/Spinner";
    import {mapState} from "vuex";
    import dataConversionMethods from "@/mixin/dataConversionMethods";

    export default {
        components: {FieldInput, FormContainer, GoogleMap, MapMarker, QRScanner, FieldTextarea, spinner},
        mixins:[requiresApiRequest,dataConversionMethods],
        props: {

        },
        data(){
            return {
                viewState: 'check-in',
                isLoading: true,
                qrCode: '',
                qrCodeError: '',
                checkInDateTime: '',
                checkOutDateTime: '',
                note: '',
                registerMovedMachineLoader: false,
	            machineWasStartLoader: false,
                checkInOutLoader: false,
                bookingLat: 0,
                bookingLng: 0,
				cubicMetersUsed: ''
            }
        },
        computed: {
            ...mapState('concrete', {
                bookingSegments: 'bookingSegments',
                apiKey:'apiKey'
			}),
            routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.bookingSegments[this.$route.params.id] !== 'undefined') ? this.bookingSegments[this.$route.params.id] : false ;
			},
			isCheckout(){
				return this.routeEntity !== false && this.routeEntity.hasCheckedIn
			}
        },
        methods: {
            goToDashboard(route){
                this.$router.push({name:'admin',params: {lang: this.$i18n.locale}})
            },
            checkInOut() {
                this.viewState = "check-in-scan"
            },
            updateLocalValues(){
                if(this.routeEntity.booking !== null){
                	console.log(this.routeEntity);
                    this.bookingLat = this.routeEntity.booking.lat;
                    this.bookingLng = this.routeEntity.booking.lng;
					this.cubicMetersUsed = this.routeEntity.cubicMetersUsed;
                }
            },
            fetchEntity(){
               
                let promises = [];
                promises.push(this.addRequiredApiRequest({type: 'generic',payload:{parameters: {explode:'booking:address,booking:lat,booking:lng,booking:siteAlwaysOpen,booking:siteOpen,booking:siteClose,booking:siteAdditionalInformation,user:name,user:mobile,booking:issuer'},entity: {'id': this.$route.params.id,'@type':'BookingSegment'}}}));
                Promise.all(promises)
                .then(response =>
                {
                    this.updateLocalValues();
                })
                .catch(error => {
                    // @todo error handling - logout / reset?
                    console.log(error);
                })
             
            },
	        attemptSubmit(){
		        if(this.routeEntity.hasCheckedIn)
			        this.checkOut();
		        else
		        	this.checkIn();
	        },
            checkIn(){
                /*this.$concreteApi.post(this.apiKey,'/check-in',{
                    bookingSegment: this.routeEntity['@id'],
                    serial: this.qrCode
                },true).then((result) => {
                    if(typeof result.result !== 'undefined' && result.result === true){
                        this.viewState = 'check-in-success';
                        this.checkInDateTime = result.time;
                    }
                }).catch(error => {
                    console.log(error);
                    if(error.status){
                        this.qrCodeError = this.$t("errors.serialIssue")
                    }
                });*/
                this.registerLogEntry({
                    entry: "pilotCheckIn",
                    bookingSegment: this.routeEntity['@id'],
	                machine: this.routeEntity['machine'],
                    serial: this.qrCode
                },true, '/pilot/check-in', (result) => {
                    if(typeof result.result !== 'undefined' && result.result === true){
                        this.viewState = 'check-in-success';
                        this.checkInDateTime = result.time;
                    }
                },
                (error) => {
                    console.log(error);
                    if(error.status){
                        this.qrCodeError = this.$t("errors.serialIssue")
	                    const bounds = this.$refs['qr-buttons'].getBoundingClientRect();
                        window.scrollTo(0,bounds.top);
                    }
                },
                (isLoading) => { this.checkInOutLoader = isLoading })
            },
            checkOut(){
                /*this.$concreteApi.post(this.apiKey,'/check-out',{
                    bookingSegment: this.routeEntity['@id'],
                    serial: this.qrCode
                },true).then((result) => {
                    if(typeof result.result !== 'undefined' && result.result === true){
                        this.viewState = 'check-out-success';
                        this.checkOutDateTime = result.time;
                    }
                }).catch(error => {
                    console.log(error);
                    if(error.status){
                        this.qrCodeError = this.$t("errors.serialIssue")
                    }
                });*/
                this.registerLogEntry({
                    entry: "pilotCheckOut",
                    bookingSegment: this.routeEntity['@id'],
	                machine: this.routeEntity['machine'],
                    serial: this.qrCode
                },true, '/pilot/check-out', (result) => {
                    if(typeof result.result !== 'undefined' && result.result === true){
                        this.viewState = 'check-out-success';
                        this.checkOutDateTime = result.time;
                    }
                },
                (error) => {
                    console.log(error);
                    if(error.status){
                        this.qrCodeError = this.$t("errors.serialIssue")
	                    const bounds = this.$refs['qr-buttons'].getBoundingClientRect();
	                    window.scrollTo(0,bounds.top);
                    }
                },
                (isLoading) => { this.checkInOutLoader = isLoading })
            },
	        triggerMachineWashStart(){
            	this.machineWasStartLoader = true;
                this.$concreteApi.post(this.apiKey,'/pilot/starting-machine-wash',{
	                bookingSegment: this.routeEntity['@id'],
                },true)
                .then(result => {
                	console.log(result)
	                this.machineWasStartLoader = false;
                })
                .catch(error => {
                	console.log(error);
	                this.machineWasStartLoader = false;
                });
	        },
            registerMovedMachine(){
                this.registerLogEntry({
                    entry: "movedMachine",
                    bookingSegment: this.routeEntity['@id'],
	                machine: this.routeEntity['machine'],
                },false, '/log_entries',
                () => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t("alerts.machineWasMovedTitle"),
                        text: this.$t("alerts.machineWasMovedDescription"),
                        confirmButtonText: this.$t("terms.ok"),
                        confirmButtonColor: "#495760"
                    }).then((result) => {
                        this.$router.push({name:'admin',params: {lang: this.$i18n.locale}})
                    });
                },
                () => {},
                (isLoading) => {this.registerMovedMachineLoader = isLoading});
            },
            registerLogEntry (payload, useBaseUrl, endPoint, resultCallBack, errorCallback, loaderCallback = () => {}) {
                loaderCallback(true);

                let lat = 0;
                let lng = 0;

                const sendPayload = () => {
                    this.$concreteApi.post(this.apiKey,endPoint,payload,useBaseUrl).then((result) => {
                        resultCallBack(result);
                        loaderCallback(false);
                    }).catch(error => {
                        console.log(error);
                        errorCallback(error);
                        loaderCallback(false);
                    });
                }

                // Add location data if possible
	            if (navigator.geolocation)
	            {
		            navigator.geolocation.getCurrentPosition((position) =>
		            {
			
			            lat = position.coords.latitude;
			            lng = position.coords.longitude;
			
			            payload.data = JSON.stringify({lat: lat, lng: lng});
			            sendPayload();
		            }, () =>
		            {
			            payload.data = JSON.stringify({lat: lat, lng: lng});
			            sendPayload();
		            });
	            }
	            else
	            {
		            payload.data = JSON.stringify({lat: lat, lng: lng});
		            sendPayload();
	            }
            },
            addNote(){
                this.$concreteApi.post(this.apiKey,'/pilot/check-out',{
                    bookingSegment: this.routeEntity['@id'],
                    serial: this.qrCode,
                    note: this.note,
					cubicMetersUsed: this.cubicMetersUsed
                },true).then((result) => {
                    if(typeof result.result !== 'undefined' && result.result === true){
                        this.$router.push({name:'admin',params: {lang: this.$i18n.locale}})
                    }
                }).catch(error => {
                    console.log(error);
                    if(error.status){
                    }
                });
            },
	        scrollToScannerBox(){
                const bounds = this.$refs['scanner-title'].getBoundingClientRect();
		        window.scrollTo(0, bounds.height);
	        }
        },
        created()
        {
            this.isFetchingRequiredApiData = true;
        },
        mounted()
		{
			this.fetchEntity();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchEntity();
				}
			},
			viewState(to){
				if(to === 'check-in-scan' && window.innerWidth < 767)
				{
					if(typeof this.$refs['scanner-title'] !== 'undefined')
					{
						this.scrollToScannerBox()
					}
					else
					{
						const interval = setInterval(() => {
							if(typeof this.$refs['scanner-title'] !== 'undefined')
							{
								clearInterval(interval);
								this.scrollToScannerBox()
							}
						},20)
					}
				}
			}
		}
    }
</script>

<style lang="scss" scoped>
    .date-time-title {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0;
    }
    
    .date {
        font-size: 1rem;
        margin-bottom: 0;
    }

    .time {
        font-size: 1.5rem
    }


    .pilot-map {
        min-height: 250px;
    }

    .address {
        background: #F0F0F0;
    }

    .buttons {

        .btn {
            font-size: 0.8rem
        }
        .btn-primary {
            background-color: $blue;
            border-color: $blue;
        }
    }

    .success-message {
        color: $grey;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .buttons .btn-primary {
        background-color: #28A745;
        border-color: #28A745;
    }

</style>
